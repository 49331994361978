<template>
  <div class="container">
    <div class="banner">
      <h1>ISA，你的安全管家</h1>
      <p>
        获得权威第三方ASIL-B软件认证，为企业功能安全提供全方位服务-功能安全管理、工程师培训、安全分析工具支持，协助企业提升产品安全品质，降低功能安全开发工作量，缩短项目开发和认证周期
      </p>
    </div>

    <div class="about">
      <div class="header">
        <h3>关于多幸智能</h3>
        <div
          style="width: 96px; height: 6px; background: #4C84FF; margin-top: 11px; margin-bottom: 59px;"
        ></div>
        <p>
          多幸智能创立于2016年，是一家致力于通过智能化、数字化软件助力企业高效完备的开展系统安全开发、管理、认证的科技公司。ISA（Intelligent
          Safety
          Analysis）通过面向用户的软件组件配合技术解读、专业化安全分析工具FMEA/FTA，结合协同式管理及数据一站式交互，解决行业中标准落地难、分析工具缺乏、认证周期长等行业痛点。
        </p>
      </div>
    </div>

    <div class="development-history">
      <h3>发展历程</h3>
      <div
        style="width: 64px; height: 6px; background: #4C84FF; margin-top: 11px; margin-bottom: 182px;"
      ></div>
    </div>

    <div class="experience">
      <h3>欢迎免费体验我们的系统</h3>
      <div class="btns">
        <div class="trial-btn" @click="goRegister"><span>试用</span></div>
<!--        <div class="login-btn" @click="goLogin"><span>登录</span></div>-->
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
  methods: {
    goRegister() {
      window.open("https://isa.homelybeauty.com/user/register");
    },
    goLogin() {
      window.open("https://isa.homelybeauty.com");
    }
  }
};
</script>

<style scoped lang="scss">
.container {
}

.about {
  height: 550px;
  background: #ffffff;
}

.banner {
  height: 770px;
  background-image: url("../assets/image/home/banner.png");
  background-size: 100% 770px;
  background-repeat: no-repeat;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & h1 {
    font-size: 84px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 84px;
    margin-bottom: 0;
  }

  & p {
    width: 606px;
    height: 90px;
    font-size: 20px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 30px;
    margin-top: 40px;
  }
}

.about {
  & .header {
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & h3 {
      margin-top: 149px;
      margin-bottom: 0;
      height: 50px;
      font-size: 36px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 50px;
    }

    & p {
      width: 860px;
      height: 120px;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 30px;
    }
  }
}

.development-history {
  height: 550px;
  background-color: #f5faff;
  background-image: url("../assets/image/home/development-history-bg1.png");
  background-size: 100% 550px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  & h3 {
    margin-top: 90px;
    margin-bottom: 0;
    height: 50px;
    font-size: 36px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 50px;
  }
}

.experience {
  height: 450px;
  background: #2284ef;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  & h3 {
    height: 48px;
    font-size: 48px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 48px;
    margin-top: 140px;
    margin-bottom: 60px;
  }
}

.btns {
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  & :hover {
    cursor: pointer;
  }
}

.trial-btn {
  width: 140px;
  height: 60px;
  background: #ffffff;
  border-radius: 9px;
  //margin-right: 30px;
  & span {
    width: 44px;
    height: 20px;
    font-size: 22px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #2284ef;
    line-height: 60px;
  }
}

.login-btn {
  width: 140px;
  height: 56px;
  background: rgba(255, 255, 255, 0);
  border-radius: 9px;
  border: 2px solid #ffffff;
  & span {
    width: 44px;
    height: 20px;
    font-size: 22px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 60px;
  }
}
</style>
